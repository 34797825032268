import React, { useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SubNavi = ({ list, siteUrl }) => {
  const toggle = useRef(null);

  const toggleMenu = (e) => {
    e.stopPropagation();
    const { current } = toggle;
    const { previousSibling: aDom } = current.previousSibling;
    aDom.classList.toggle('active');
  };

  useEffect(() => {
    const { current: ref } = toggle;
    const span = ref.parentNode.querySelector('li > span');

    ref.addEventListener('click', toggleMenu);
    if (span) {
      ref.parentNode.addEventListener('click', toggleMenu);
    }
    return () => {
      ref.removeEventListener('click', toggleMenu);
      if (span) {
        ref.parentNode.removeEventListener('click', toggleMenu);
      }
    };
  }, []);

  return (
    <>
      <ul>
        {list.map((sub) => (
          <li key={sub.id}>
            <Link
              activeClassName="active"
              to={sub.url.replace(siteUrl, '')}
              dangerouslySetInnerHTML={{ __html: sub.label }}
            />
          </li>
        ))}
      </ul>
      <i ref={toggle}>
        {/* <svg /> */}
        <FontAwesomeIcon icon={['fas', 'chevron-down']} />
      </i>
    </>
  );
};

SubNavi.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  siteUrl: PropTypes.string.isRequired,
};

export default SubNavi;
