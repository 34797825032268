import React from 'react';
import {
  FaPhone,
  FaEnvelope,
  FaMapMarkedAlt,
} from 'react-icons/fa';
import { Link } from 'gatsby';


const MobileTab = () => (
  <div className="mobile-tab">
    <nav>
      <a
        title="Anfart"
        rel="noopener noreferrer"
        target="_blank"
        href="https://goo.gl/maps/z5Gya3zRPTyHSBJ88"
      >
        <FaMapMarkedAlt />
      </a>
      <a title="Telefon" aria-label="Telefon" href="tel:+496441679290"><FaPhone /></a>
      <Link title="Kontakt" aria-label="Kontakt" to="/kontakt"><FaEnvelope /></Link>
    </nav>
  </div>
);

export default MobileTab;
