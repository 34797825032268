import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import MobileTab from './block/mobile-tab';
import Header from './header/header';
import Footer from './footer/footer';
import Privacy from './privacy';

import '../styles/gatsby.scss';
import '../styles/vendor.scss';

/**
 * @tailwind: #___gatsby
 */
export default function Layout({ children }) {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Header siteTitle={data.site.siteMetadata.title} />
          {children}
          <Footer />
          <Privacy />
          <MobileTab />
        </>
      )}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
