import React from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';

export default function FooterNavi() {
  return (
    <StaticQuery
      query={graphql`
        fragment menuItem on WPGraphQL_MenuItem {
          id
          url
          label
        }

        query NaviFooter {
          wpgraphql {
            generalSettings {
              url
            }

            footerCol1: menuItems(where: { location: FOOTER_COL_1 }) {
              nodes {
                ...menuItem
              }
            }
            footerCol2: menuItems(where: { location: FOOTER_COL_2 }) {
              nodes {
                ...menuItem
              }
            }
            footerCol3: menuItems(where: { location: FOOTER_COL_3 }) {
              nodes {
                ...menuItem
              }
            }
            footerCol4: menuItems(where: { location: FOOTER_COL_4 }) {
              nodes {
                ...menuItem
              }
            }
          }
        }
      `}
      render={({
        wpgraphql: {
          generalSettings: { url: siteUrl },
          footerCol1,
          footerCol2,
          footerCol3,
          footerCol4,
        },
      }) => (
        <div className="footer__navi">
          {[footerCol1, footerCol2, footerCol3, footerCol4].map(
            ({ nodes }, index) =>
              nodes.length > 0 && (
                <div key={index}>
                  <ul>
                    {nodes.map(({ label, url }) => (
                      <li key={url}>
                        {url === '#' ? <div>{label}</div> : <Link to={url.replace(siteUrl, '')}>{label}</Link>}
                      </li>
                    ))}
                  </ul>
                </div>
              )
          )}
        </div>
      )}
    />
  );
}
