import React from 'react';
import { Location } from '@reach/router';
import { Link } from 'gatsby';

import classNames from '../../helper/class-names';

import SubNavi from './sub-navi';

const isActive = (check) => (check ? 'active selected' : '');

export default function MainNavi({
  wpgraphql: {
    menuItems: { nodes },
    generalSettings: { url: siteUrl },
  },
}) {
  if (!nodes.length) {
    return null;
  }

  return nodes
    .filter(({ parentId }) => parentId === null)
    .map(({ url, id, label, childItems }) => (
      <li key={id} className={classNames(childItems.nodes.length && 'submenu')}>
        {url.indexOf('#') === 0 ? (
          <Location>
            {({ location: { pathname } }) => (
              <span
                className={isActive(pathname.indexOf(url.replace('#', '/')) === 0)}
                dangerouslySetInnerHTML={{ __html: label }}
              />
            )}
          </Location>
        ) : (
          <Link to={url.replace(siteUrl, '')} activeClassName="active selected" partiallyActive>
            <span dangerouslySetInnerHTML={{ __html: label }} />
          </Link>
        )}
        {childItems.nodes.length > 0 && <SubNavi list={childItems.nodes} siteUrl={siteUrl} />}
      </li>
    ));
}
