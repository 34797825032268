import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const Brand = ({ title }) => (
  <StaticQuery
    query={graphql`
      query {
        brand: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            gatsbyImageData(width: 512, placeholder: BLURRED)
          }
        }
      }
    `}
    render={(data) => (
      <Link className="header__brand" to="/" title={title}>
        <GatsbyImage image={data.brand.childImageSharp.gatsbyImageData} alt={title} />
      </Link>
    )}
  />
);

Brand.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Brand;
