import React, { useRef, useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import classNames from '../../helper/class-names';
import { useLayoutEffect } from '../../hook/isomorphic-layout-effect';
import Brand from './brand';
import MainNavi from './main-navi';

export default function Header() {
  const header = useRef(null);
  const [isActive, setActive] = useState(false);

  const toggleNavi = () => {
    const { body } = document;
    const next = !isActive;

    if (!next) {
      body.classList.remove('body__scroll--disabled');
    } else {
      body.classList.add('body__scroll--disabled');
    }

    setActive(next);
  };

  const checkScroll = () => {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const { current } = header;

    if (top && !current.classList.contains('header--active')) {
      current.classList.add('header--active');
    } else if (!top) {
      current.classList.remove('header--active');
    }
  };

  useLayoutEffect(() => {
    checkScroll();
    window.addEventListener('scroll', checkScroll);

    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query NaviTop {
          wpgraphql {
            generalSettings {
              url
              title
              description
            }

            menuItems(where: { location: HEADER_TOPBAR }) {
              nodes {
                id
                label
                url
                parentId
                childItems {
                  nodes {
                    id
                    label
                    url
                  }
                }
              }
            }
          }
        }
      `}
      render={({ wpgraphql }) => {
        return (
          <header className={classNames('header', isActive && 'active')} ref={header}>
            <div className="header__container">
              <button
                type="button"
                className={classNames('sandwich', isActive && 'sandwich--close')}
                onClick={toggleNavi}
              >
                <i />
              </button>
              <div className={classNames('header__navi--brand', isActive && 'active')}>
                <Brand title={wpgraphql.generalSettings.title} />
              </div>
              <ul className={classNames('header__navi header__navi--main', isActive && 'header__navi--active')}>
                <MainNavi wpgraphql={wpgraphql} />
              </ul>
            </div>
          </header>
        );
      }}
    />
  );
}
